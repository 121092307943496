import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  useTheme,
} from '@mui/material';
import {
  Mic,
  Stop,
  VideoCall,
  VideocamOff,
  Send,
  StopCircle,
  Language,
} from '@mui/icons-material';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

const Interview = () => {
    const { t, i18n } = useTranslation();
  const [showLanguageDialog, setShowLanguageDialog] = useState(true);
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setShowLanguageDialog(false);
    setShowInfoDialog(true);
  };

  // Retrieve the 'shareUrl' parameter from the URL
  const { shareUrl } = useParams();

  // Basic state variables
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  // Interview state variables
  const [interviewStatus, setInterviewStatus] = useState('init'); // Possible values: 'init', 'recording', 'answering', 'ended'
  const [messages, setMessages] = useState([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [interviewUuid, setInterviewUuid] = useState(null);
  const [isConnectionLost, setIsConnectionLost] = useState(false);
  const retryTimeoutRef = useRef(null);

  // Media-related state variables
  const [videoEnabled, setVideoEnabled] = useState(false); // Video preview is disabled by default
  const [currentRecording, setCurrentRecording] = useState(null); // Holds the recording stream

  // Refs for various purposes
  const videoRef = useRef(null); // Reference to the video element for preview
  const videoStreamRef = useRef(null); // Holds the video preview stream
  const mediaRecorderRef = useRef(null); // Holds the media recorder for audio answers
  const wsRef = useRef(null); // Holds the WebSocket connection
  const timerRef = useRef(null); // Holds the timer interval
  const fullVideoChunks = useRef([]); // Holds the chunks of the full video recording
  const currentAnswerChunks = useRef([]); // Holds the chunks of the current answer audio
  const messagesContainerRef = useRef(null); // Reference to the messages container for auto-scrolling

  // Interviewee info form state
  const [intervieweeInfo, setIntervieweeInfo] = useState({
    name: '',
    age: '',
    gender: '',
    occupation: '',
    education: '',
    contact: '',
    notes: '',
  });
  const [showInfoDialog, setShowInfoDialog] = useState(false); // Controls the visibility of the info dialog
  const [isSubmitting, setIsSubmitting] = useState(false); // Indicates if the form is being submitted

  // Function to toggle the video preview on and off
  const handleToggleVideo = () => {
    setVideoEnabled((prev) => !prev);
  };

  // Function to stop video recording entirely
  const stopVideoRecording = () => {
    stopAllMediaStreams(); // Stops all media streams
    setVideoEnabled(false); // Disables video preview
  };

  // Fetch the interview project when the component mounts or when 'shareUrl' changes
  useEffect(() => {
    fetchProjectByShareUrl();
    return () => cleanup();
  }, [shareUrl]);

  // Scroll to the bottom of the messages container when messages change
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Handle changes in 'videoEnabled' state
  useEffect(() => {
    const updateVideoStreams = async () => {
      if (interviewStatus === 'recording' || interviewStatus === 'answering') {
        // If an interview is ongoing, reinitialize media streams to reflect the change in 'videoEnabled'
        await reinitializeMediaStreams();
      } else {
        // If no interview is ongoing, just start or stop the preview video
        if (videoEnabled) {
          await startPreviewVideo();
        } else {
          stopPreviewVideo();
        }
      }
    };

    updateVideoStreams();

    return () => {
      // Cleanup the preview video when the component unmounts or 'videoEnabled' changes
      stopPreviewVideo();
    };
  }, [videoEnabled]);

  // Function to clean up resources when the component unmounts
  const cleanup = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    if (wsRef.current) wsRef.current.close();
    stopAllMediaStreams();
  };

  // Function to fetch the interview project using the 'shareUrl'
  const fetchProjectByShareUrl = async () => {
    try {
      const response = await axios.get(`/api/interview/${shareUrl}`);
      setProject(response.data);
      setMessages((prev) => [
        ...prev,
        {
          role: 'ai',
          content: t('interview.messages.welcome'),
          timestamp: new Date().toISOString(),
        },
      ]);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch project:', error);
      setMessages((prev) => [
        ...prev,
        {
          role: 'ai',
          content:
            'Hello! There was an issue loading the interview project, but you can continue browsing the page or try refreshing.',
          timestamp: new Date().toISOString(),
        },
      ]);
      alert(
        error.response?.data?.detail ||
        'Failed to load the interview project, please check if the link is correct'
      );
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the submission of the interviewee info form
  const handleInfoSubmit = async () => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const formData = {
        ...intervieweeInfo,
        age: intervieweeInfo.age ? parseInt(intervieweeInfo.age) : undefined,
      };
      const response = await axios.post(`/api/interview/start/${shareUrl}`, formData);
      const uuid = response.data.interview_uuid;
      if (!uuid || typeof uuid !== 'string') {
        throw new Error('Invalid interview UUID received from server');
      }
      setInterviewUuid(uuid);
      setShowInfoDialog(false);
      await initializeInterview(uuid);
      setInterviewStatus('recording');
    } catch (error) {
      console.error('Error in handleInfoSubmit:', error);
      alert(t('interview.startFailed') || 'Failed to start the interview.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to handle skipping the interviewee info form
  const handleSkipForm = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`/api/interview/start/${shareUrl}`, {});
      const uuid = response.data.interview_uuid;
      if (!uuid || typeof uuid !== 'string') {
        throw new Error('Invalid interview UUID received from server');
      }
      setInterviewUuid(uuid);
      setShowInfoDialog(false);
      await initializeInterview(uuid);
      setInterviewStatus('recording');
    } catch (error) {
      console.error('Error skipping form:', error);
      alert(
        'Failed to start interview: ' + (error.response?.data?.detail || error.message)
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to initialize the interview
  const initializeInterview = async (uuid) => {
    try {
      if (!uuid) {
        throw new Error('Invalid interview UUID');
      }
      await setupMediaStreams(); // Set up the necessary media streams
      await setupWebSocketConnection(uuid); // Set up the WebSocket connection
      startTimer(); // Start the interview timer
      await startFullVideoRecording(); // Start recording the full interview
      setInterviewStatus('recording');
    } catch (error) {
      console.error('Error initializing interview:', error);
      cleanup();
      alert('Failed to initialize interview: ' + error.message);
    }
  };

  // Function to set up the WebSocket connection
  const setupWebSocketConnection = async (uuid) => {
    return new Promise((resolve, reject) => {
      try {
        if (!uuid || typeof uuid !== 'string') {
          throw new Error('Invalid interview UUID');
        }
        const wsUrl = `wss://starfish-app-73rfk.ondigitalocean.app/api/ws/interview/${uuid}`;
        wsRef.current = new WebSocket(wsUrl);
        wsRef.current.onopen = () => {
          setIsConnectionLost(false);
          resolve();
        };
        wsRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
          reject(error);
        };
        wsRef.current.onclose = (event) => {
          handleWebSocketClose(event);
        };
        wsRef.current.onmessage = (event) => {
          handleWebSocketMessage(event);
        };
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to handle incoming WebSocket messages
  const handleWebSocketMessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'end_interview') {
        endInterview();
      } else if (data.type === 'ai_response') {
        setMessages((prev) => [
          ...prev,
          {
            role: 'ai',
            content: data.message,
            timestamp: data.timestamp,
          },
        ]);
      }
    } catch (error) {
      console.error('Error handling WebSocket message:', error);
    }
  };

  // Function to handle WebSocket closure
  const handleWebSocketClose = (event) => {
    if (interviewStatus === 'recording') {
      alert('Interview connection lost, please refresh the page and try again');
      cleanup();
    }
  };

  // Function to start the video preview
  const startPreviewVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoStreamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
      alert('Unable to access the camera. Please check permissions.');
    }
  };

  // Function to stop the video preview
  const stopPreviewVideo = () => {
    if (videoStreamRef.current) {
      videoStreamRef.current.getTracks().forEach((track) => track.stop());
      videoStreamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  // Function to reinitialize media streams during an ongoing interview when 'videoEnabled' changes
  const reinitializeMediaStreams = async () => {
    try {
      // Stop current recording
      if (currentRecording) {
        currentRecording.getTracks().forEach((track) => track.stop());
        setCurrentRecording(null);
      }
      // Stop preview video
      stopPreviewVideo();

      // Setup new media streams based on 'videoEnabled' state
      await setupMediaStreams();
      // Restart full video recording with new constraints
      await startFullVideoRecording();
    } catch (error) {
      console.error('Error reinitializing media streams:', error);
    }
  };

  // Function to set up media streams for recording
  const setupMediaStreams = async () => {
    try {
      // Always request audio permissions
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
      });

      let videoStream = null;
      if (videoEnabled) {
        // Request video permissions only if video is enabled
        videoStream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1280, height: 720 },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = videoStream;
        }
      } else {
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
      }

      // Combine audio and video streams
      let combinedStream;
      if (videoEnabled && videoStream) {
        const audioTracks = audioStream.getAudioTracks();
        combinedStream = new MediaStream([...audioTracks, ...videoStream.getVideoTracks()]);
      } else {
        combinedStream = audioStream;
      }

      return combinedStream;
    } catch (error) {
      console.error('Error setting up media streams:', error);
      throw error;
    }
  };

  // Function to start recording the full interview
  const startFullVideoRecording = async () => {
    try {
      const combinedStream = await setupMediaStreams();

      const recorder = new MediaRecorder(combinedStream, {
        mimeType: 'video/webm',
      });

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          fullVideoChunks.current.push(event.data);
        }
      };

      recorder.start(1000); // Start recording in chunks of 1 second
      setCurrentRecording(combinedStream);
    } catch (error) {
      console.error('Error starting full video recording:', error);
      alert('Unable to start video recording, please check device permissions');
    }
  };

  // Function to start recording an answer
  const startAnswering = async () => {
    if (interviewStatus !== 'recording') {
      console.log('Cannot start answering in current state:', interviewStatus);
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      currentAnswerChunks.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          currentAnswerChunks.current.push(event.data);
        }
      };

      mediaRecorder.start(1000); // Start recording in chunks of 1 second
      setInterviewStatus('answering');
    } catch (error) {
      console.error('Error starting answer recording:', error);
      alert('Failed to start recording, please check microphone permissions');
    }
  };

  // Function to end recording an answer
  const endAnswer = async () => {
    if (interviewStatus !== 'answering' || !mediaRecorderRef.current) return;

    try {
      mediaRecorderRef.current.stop();
      const blob = new Blob(currentAnswerChunks.current, { type: 'audio/webm' });
      const base64Audio = await blobToBase64(blob);

      wsRef.current.send(
        JSON.stringify({
          type: 'end_answer',
          audio: base64Audio,
          timestamp: new Date().toISOString(),
        })
      );

      setInterviewStatus('recording');
      currentAnswerChunks.current = [];
    } catch (error) {
      console.error('Error ending answer:', error);
    }
  };

  // Function to end the interview
  const endInterview = async () => {
    try {
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }

      if (!interviewUuid) {
        console.error('No valid interview UUID found');
        return;
      }

      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      stopAllMediaStreams();

      if (fullVideoChunks.current.length > 0) {
        const fullVideoBlob = new Blob(fullVideoChunks.current, { type: 'video/webm' });
        const formData = new FormData();
        formData.append('video', fullVideoBlob, 'interview.webm');

        await axios.post(`/api/interview/${interviewUuid}/upload-video`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      setInterviewStatus('ended');
      cleanup();
    } catch (error) {
      console.error('Error ending interview:', error);
      alert('An error occurred when ending the interview: ' + error.message);
    }
  };

  // Helper function to convert a Blob to a Base64 string
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Function to start the interview timer
  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setElapsedTime((prev) => {
        if (prev >= 3600) {
          endInterview(); // Automatically end the interview after 1 hour
          return prev;
        }
        return prev + 1;
      });
    }, 1000);
  };

  // Helper function to format elapsed time in MM:SS format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Function to stop all media streams
  const stopAllMediaStreams = () => {
    // Stop the preview video stream
    if (videoStreamRef.current) {
      videoStreamRef.current.getTracks().forEach((track) => track.stop());
      videoStreamRef.current = null;
    }

    // Stop the recording video stream
    if (currentRecording) {
      currentRecording.getTracks().forEach((track) => track.stop());
      setCurrentRecording(null);
    }

    // Stop audio recording stream
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  // Function to handle starting or ending the interview
  const handleStartOrEndInterview = () => {
    if (interviewStatus === 'recording' || interviewStatus === 'answering') {
      endInterview();
    } else if (interviewStatus === 'init') {
      if (!interviewUuid) {
        setShowLanguageDialog(true);
      }
    }
  };

  // Render the JSX
return (
    <Container maxWidth="lg">
        {/* Alert if the connection is lost */}
        {isConnectionLost && (
            <Alert severity="warning" sx={{ mb: 2 }}>
                Connection lost, attempting to reconnect...
            </Alert>
        )}
        <Dialog
            open={showLanguageDialog}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Select your language / 选择语言 / Choisissez votre langue / Velg språk
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('en')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            English
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('zh')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            中文
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('fr')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            Français
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('nb')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            Norsk
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

        {/* Dialog for the interviewee info form */}
        <Dialog
            open={showInfoDialog}
            onClose={() => {
                if (!isSubmitting) setShowInfoDialog(false);
            }}
            maxWidth="md"
            fullWidth
            disableEscapeKeyDown={isSubmitting}
        >
            <DialogTitle>
                {t('interview.basicInfo')}
            </DialogTitle>
            <DialogContent>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        {/* Name Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.name')}
                                value={intervieweeInfo.name}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, name: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Age Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.age')}
                                type="number"
                                value={intervieweeInfo.age}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, age: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Gender Field */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{t('interview.fields.gender')}</InputLabel>
                                <Select
                                    value={intervieweeInfo.gender}
                                    onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, gender: e.target.value }))}
                                >
                                    <MenuItem value="male">{t('interview.gender.male')}</MenuItem>
                                    <MenuItem value="female">{t('interview.gender.female')}</MenuItem>
                                    <MenuItem value="other">{t('interview.gender.other')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Occupation Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.occupation')}
                                value={intervieweeInfo.occupation}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, occupation: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Education Field */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.education')}
                                value={intervieweeInfo.education}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, education: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Contact Field */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.contact')}
                                value={intervieweeInfo.contact}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, contact: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Notes Field */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.platformId')}
                                multiline
                                rows={4}
                                value={intervieweeInfo.notes}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, notes: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSkipForm} disabled={isSubmitting}>
                    {isSubmitting ? t('interview.controls.starting') : t('interview.controls.skipForm')}
                </Button>
                <Button variant="contained" onClick={handleInfoSubmit} disabled={isSubmitting}>
                    {isSubmitting ? t('interview.controls.starting') : t('interview.controls.startInterview')}
                </Button>
            </DialogActions>
        </Dialog>
    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            {t('interview.note')}
    </Typography>
        {/* Main Content */}
        <Paper sx={{ p: 3, my: 4 }}>
            {/* Project Name */}
            <Typography variant="h4" gutterBottom>
                {project?.name || t('interview.title')}
            </Typography>

            {/* Control Buttons */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <Button
                    variant="contained"
                    color={interviewStatus === 'recording' || interviewStatus === 'answering' ? 'error' : 'primary'}
                    startIcon={interviewStatus === 'recording' || interviewStatus === 'answering' ? <Stop /> : <Mic />}
                    onClick={handleStartOrEndInterview}
                    disabled={isSubmitting}
                >
                    {interviewStatus === 'recording' || interviewStatus === 'answering'
                        ? t('interview.controls.endInterview')
                        : t('interview.controls.startInterview')}
                </Button>
                {(interviewStatus === 'recording' || interviewStatus === 'answering') && (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Mic />}
                            onClick={startAnswering}
                            disabled={interviewStatus === 'answering'}
                        >
                            {interviewStatus === 'answering'
                                ? t('interview.controls.recording')
                                : t('interview.controls.startAnswer')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<Send />}
                            onClick={endAnswer}
                            disabled={interviewStatus !== 'answering'}
                        >
                            {t('interview.controls.endAnswer')}
                        </Button>
                    </>
                )}
            </Box>

            {/* Interview Log */}
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    {t('interview.sections.interviewLog')}
                </Typography>
                <Paper variant="outlined" sx={{ p: 2, maxHeight: '300px', overflowY: 'auto', bgcolor: '#f5f5f5' }}>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                mb: 2,
                                p: 1,
                                bgcolor: msg.role === 'ai' ? '#e3f2fd' : '#fff',
                                borderRadius: 1,
                            }}
                        >
                            <Typography variant="subtitle2" color="text.secondary">
                                {msg.role === 'ai' ? t('interview.messages.aiInterviewer') : t('interview.messages.interviewee')}:
                            </Typography>
                            <Typography>{msg.content}</Typography>
                        </Box>
                    ))}
                </Paper>
            </Box>
        </Paper>

        {/* Elapsed Time Display */}
        <Box sx={{ position: 'fixed', top: 20, right: 20 }}>
            <Typography variant="h6">
                {t('interview.status.elapsedTime')}: {formatTime(elapsedTime)}
            </Typography>
        </Box>

        {/* Video Controls */}
        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
                onClick={handleToggleVideo}
                variant="contained"
                startIcon={videoEnabled ? <VideocamOff /> : <VideoCall />}
            >
                {videoEnabled ? t('interview.video.turnOff') : t('interview.video.turnOn')}
            </Button>

            {(interviewStatus === 'recording' || interviewStatus === 'answering') && (
                <Button
                    onClick={stopVideoRecording}
                    variant="contained"
                    color={videoEnabled ? 'error' : 'grey'}
                    startIcon={<StopCircle />}
                >
                    {t('interview.video.stopRecording')}
                </Button>
            )}
        </Box>

        {videoEnabled && (
            <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                style={{ width: '100%', maxWidth: '500px', marginTop: '20px' }}
            />
        )}
    </Container>
);
};

export default Interview;
