import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios';  // 修改导入
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const formData = new URLSearchParams();
      formData.append('username', userData.username);
      formData.append('email', userData.email);
      formData.append('password', userData.password);

      await axiosInstance.post('/api/register', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      alert('Registered Successfully');
      navigate('/login');
    } catch (error) {
      console.error('Registration failed:', error);
      alert(error.response?.data?.detail || 'Registration failed');
    }
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 400, mx: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        {t('auth.actions.register')}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.username')}
          value={userData.username}
          onChange={(e) => setUserData({ ...userData, username: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.email')}
          type="email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.password')}
          type="password"
          value={userData.password}
          onChange={(e) => setUserData({ ...userData, password: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.confirmPassword')}
          type="password"
          value={userData.confirmPassword}
          onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
          {t('auth.actions.register')}
        </Button>
      </Box>
    </Paper>
  );
};

export default Register;