import React, { useState, useEffect } from 'react';
import { 
    Paper, 
    Typography, 
    Box, 
    TextField, 
    Button, 
    Grid,
    Divider,
    CircularProgress,
    Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../utils/axios';

const Profile = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        credits: 0
    });
    const [loading, setLoading] = useState(true);
    const [passwords, setPasswords] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [message, setMessage] = useState({ type: '', content: '' });

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            setLoading(true);
            
            // Log the request being made
            console.log('Fetching user profile from:', '/api/user/profile');
            
            // If you're using a token for authentication, include it in the headers
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            
            const response = await axios.get('/api/user/profile', config);
            
            console.log('Profile response:', response.data);
            
            if (response.data) {
                setUserData({
                    username: response.data.username ?? 'unknown',
                    email: response.data.email ?? 'unknown',
                    credits: response.data.credits ?? 'unknown',
                });
            } else {
                throw new Error('No data received from the server');
            }
        } catch (error) {
            console.error('Profile fetch error:', error);
            
            // Log more details about the error
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            
            if (error.response?.status === 401) {
                console.log('Unauthorized access. Redirecting to login...');
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                setMessage({
                    type: 'error',
                    content: error.response?.data?.detail || 'Failed to fetch user information'
                });
            }
        } finally {
            setLoading(false);
        }
    };


    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (passwords.newPassword !== passwords.confirmPassword) {
            setMessage({
                type: 'error',
                content: 'Passwords do not match'
            });
            return;
        }

        try {
            await axios.post('/api/user/change-password', {
                old_password: passwords.oldPassword,
                new_password: passwords.newPassword
            });
            
            setMessage({
                type: 'success',
                content: 'Password changed successfully'
            });
            setPasswords({
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
        } catch (error) {
            setMessage({
                type: 'error',
                content: error.response?.data?.detail || 'Failed to change password'
            });
        }
    };
    // Add the loading check here, before the main return
    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                    <Typography variant="h5" gutterBottom>
                        {t('profile.title')}
                    </Typography>
                    {message.content && (
                        <Alert severity={message.type} sx={{ mb: 2 }}>
                            {message.content}
                        </Alert>
                    )}
                    <Box sx={{ mb: 4 }}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label={t('profile.fields.username')}
                            value={userData.username}
                            disabled
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label={t('profile.fields.email')}
                            value={userData.email}
                            disabled
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label={t('profile.fields.credits')}
                                value={userData.credits}
                                disabled
                                helperText={t('profile.messages.creditsHelp')}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/recharge')}
                                sx={{ mt: 2, height: 40 }}
                            >
                                {t('profile.actions.recharge')}
                            </Button>
                        </Box>
                    </Box>
                    
                    <Divider sx={{ my: 3 }} />
                    
                    <Typography variant="h6" gutterBottom>
                        {t('profile.actions.changePassword')}
                    </Typography>
                    <Box component="form" onSubmit={handlePasswordChange}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label={t('auth.fields.currentPassword')}
                            type="password"
                            value={passwords.oldPassword}
                            onChange={(e) => setPasswords({...passwords, oldPassword: e.target.value})}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label={t('auth.fields.newPassword')}
                            type="password"
                            value={passwords.newPassword}
                            onChange={(e) => setPasswords({...passwords, newPassword: e.target.value})}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label={t('auth.fields.confirmNewPassword')}
                            type="password"
                            value={passwords.confirmPassword}
                            onChange={(e) => setPasswords({...passwords, confirmPassword: e.target.value})}
                        />
                        <Button 
                            type="submit" 
                            variant="contained" 
                            sx={{ mt: 2 }}
                        >
                            {t('profile.actions.changePassword')}
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Profile;