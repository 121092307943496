import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <Select
      value={i18n.language}
      onChange={(e) => i18n.changeLanguage(e.target.value)}
      size="small"
      sx={{
        color: 'inherit',
        '.MuiSelect-select': {
          py: 0.5,
          color: 'inherit'
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }}
    >
      <MenuItem value="en">EN</MenuItem>
      <MenuItem value="zh">中文</MenuItem>
      <MenuItem value="nb">Norsk</MenuItem>
      <MenuItem value="fr">FR</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;