import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Snackbar
} from '@mui/material';
import { Add as AddIcon, ContentCopy as ContentCopyIcon, Delete as DeleteIcon, Download as DownloadIcon } from '@mui/icons-material';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentProject, setCurrentProject] = useState({ name: '', outline: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      console.log('Fetching projects...'); // Debug log
      const response = await axios.get('/api/projects');  // Keep /api prefix
      console.log('Projects data:', response.data); // Add log
      setProjects(response.data.map(project => ({
        ...project,
        project_uuid: project.project_uuid || project.uuid // Ensure uuid exists
      })));
    } catch (error) {
      console.error('Failed to fetch projects:', error);
      setSnackbar({ open: true, message: t('projects.fetchError') });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!currentProject.name || !currentProject.outline) {
        setSnackbar({ open: true, message: t('projects.fillAllFields') });
        return;
      }
  
      const projectData = {
        name: currentProject.name,
        outline: currentProject.outline,
        api_key: currentProject.api_key  // Include the API key
      };
  
      if (currentProject.id) {
        await axios.put(`/api/projects/${currentProject.id}`, projectData);
      } else {
        const response = await axios.post('/api/projects', projectData);
        console.log('Created project:', response.data);
      }
      
      await fetchProjects();
      setSnackbar({ open: true, message: t('projects.saveSuccess') });
      setOpenDialog(false);
      setCurrentProject({ name: '', outline: '', api_key: '' });
    } catch (error) {
      console.error('Failed to save project:', error.response?.data || error);
      setSnackbar({ 
        open: true, 
        message: error.response?.data?.detail || t('projects.saveFailed')
      });
    }
  };
  

  const handleCopyLink = (shareUrl) => {
    navigator.clipboard.writeText(`${window.location.origin}/interview/${shareUrl}`);
    setSnackbar({ open: true, message: t('projects.linkCopied') });
  };

  const handleDelete = async (projectId) => {
    if (window.confirm(t('projects.confirmDelete'))) {
      try {
        await axios.delete(`/api/projects/${projectId}`);
        await fetchProjects();
        setSnackbar({ open: true, message: t('Project delete success') });
      } catch (error) {
        console.error('Failed to delete project:', error);
        setSnackbar({ open: true, message: t('projects.deleteFailed') });
      }
    }
  };

  const handleDownload = async (uuid) => {
    try {
      setDownloadProgress(0);
      const response = await axios.get(`/api/projects/export/${uuid}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted);
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-${uuid}-all-data.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadProgress(0);
      setSnackbar({ open: true, message: t('projects.downloadSuccess') });
    } catch (error) {
      console.error('Failed to download:', error);
      setSnackbar({ open: true, message: t('projects.downloadFailed') });
    }
  };

  return (
    <Container>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{t('projects.title')}</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setCurrentProject({ name: '', outline: '', api_key: '' });
              setOpenDialog(true);
            }}
          >
            {t('projects.actions.create')}
          </Button>
        </Grid>
        
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>{project.name}</Typography>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {project.outline}
                </Typography>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  onClick={() => navigate(`/projects/${project.project_uuid || project.uuid}`)}
                >
                  {t('common.buttons.view')}
                </Button>
                <Button 
                  size="small" 
                  onClick={() => {
                    setCurrentProject(project);
                    setOpenDialog(true);
                  }}
                >
                  {t('common.buttons.edit')}
                </Button>
                <IconButton 
                  size="small" 
                  onClick={() => handleCopyLink(project.share_url)}
                  title={t('projects.actions.copyLink')}
                >
                  <ContentCopyIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDownload(project.project_uuid)}
                  title={t('common.buttons.download')}
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDelete(project.id)}
                  title={t('common.buttons.delete')}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {currentProject.id ? t('projects.actions.edit') : t('projects.actions.create')}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('projects.fields.name')}
            value={currentProject.name}
            onChange={(e) => setCurrentProject({ ...currentProject, name: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t('projects.fields.outline')}
            value={currentProject.outline}
            onChange={(e) => setCurrentProject({ ...currentProject, outline: e.target.value })}
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>
            {t('common.buttons.cancel')}
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {t('common.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Container>
  );
};

export default Projects;