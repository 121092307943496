import React, { useState, useEffect } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

const RECHARGE_OPTIONS = [
    { credits: 100, price: 10 },
    { credits: 500, price: 45 },
    { credits: 1000, price: 80 },
];

const Recharge = () => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(null);
    const [message, setMessage] = useState('');
    const [currentCredits, setCurrentCredits] = useState(0);

    useEffect(() => {
        axios.get('/api/user/profile').then(response => {
            setCurrentCredits(response.data.credits);
        }).catch(error => {
            console.error('Failed to fetch user credits:', error);
            setMessage(t('errors.general.somethingWrong'));
        });
    }, [t]);

    const createOrder = (data, actions) => {
        if (!selectedOption) {
            setMessage(t('recharge.selectOption'));
            return;
        }
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: selectedOption.price.toString()
                },
                description: t('recharge.credits', { count: selectedOption.credits })
            }]
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(details => {
            axios.post('/api/user/recharge/verify', {
                order_id: details.id,
                credits: selectedOption.credits,
                payment_source: 'paypal',
                amount: selectedOption.price
            }).then(response => {
                setCurrentCredits(response.data.credits);
                setMessage(t('recharge.success', { credits: selectedOption.credits }));
            }).catch(error => {
                console.error('Payment verification failed:', error);
                setMessage(t('recharge.error'));
            });
        }).catch(error => {
            console.error('Capture error:', error);
            setMessage(t('recharge.error.paypal'));
        });
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f4f4f4', borderRadius: '8px', margin: '20px' }}>
            <h1>{t('recharge.title')}</h1>
            <p>{t('recharge.currentCredits')}: {currentCredits}</p>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                {RECHARGE_OPTIONS.map(option => (
                    <button 
                        key={option.credits} 
                        onClick={() => setSelectedOption(option)}
                        style={{
                            padding: '10px 20px',
                            margin: '5px',
                            backgroundColor: '#0070f3',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease'
                        }}
                    >
                        {t('recharge.credits', { count: option.credits })} - ${option.price}
                    </button>
                ))}
            </div>
            {selectedOption && (
                <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 6px rgba(0,0,0,0.1)' }}>
                    <h2>
                        {t('recharge.selected', {
                            credits: selectedOption.credits,
                            price: selectedOption.price
                        })}
                    </h2>
                    <PayPalButtons
                        style={{ layout: "horizontal", height: 40, tagline: false }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                    />
                </div>
            )}
            {message && <p style={{ color: 'red', padding: '10px' }}>{message}</p>}
        </div>
    );
};

export default Recharge;