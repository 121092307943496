// App.js
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import Interview from './pages/Interview';
import Recharge from './pages/Recharge';
import About from './pages/About';
import PrivateRoute from './components/PrivateRoute';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

function AppContent() {
  return (
    <MainLayout>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:uuid" element={<ProjectDetail />} />
        <Route path="/interview/:shareUrl" element={<Interview />} />
        <Route path="/about" element={<About />} /> {/* Add this line */}
        <Route 
          path="/recharge" 
          element={
            <PrivateRoute>
              <Recharge />
            </PrivateRoute>
          } 
        />
      </Routes>
    </MainLayout>
  );
}

const App = () => {
  const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
    components: "buttons",
    debug: true  // 修改为 false
  };

  console.log("PayPal Client ID:", paypalOptions["client-id"]); // Add this line

  return (
    <PayPalScriptProvider options={paypalOptions} deferLoading={false}>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
    </PayPalScriptProvider>
  );
};

export default App;