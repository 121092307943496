import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axios';

const Login = () => {
  const { t } = useTranslation();
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log('Attempting login...'); // Debug log
      const formData = new URLSearchParams(); // Use URLSearchParams to format data
      formData.append('username', credentials.username);
      formData.append('password', credentials.password);

      const response = await axiosInstance.post('/api/login', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log('Login response:', response.data); // Debug log
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('username', credentials.username); // Save username
        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error.response?.data || error);
      alert(error.response?.data?.detail || 'Login failed, please try again');
    }
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 400, mx: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        {t('auth.actions.login')}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.username')}
          value={credentials.username}
          onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.password')}
          type="password"
          value={credentials.password}
          onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
        />
        <Button 
          type="submit" 
          fullWidth 
          variant="contained" 
          sx={{ mt: 3 }}
        >
          {t('auth.actions.login')}
        </Button>
      </Box>
    </Paper>
  );
};

export default Login;