import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  CircularProgress,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  CloudDownload as CloudDownloadIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

const ProjectDetail = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [project, setProject] = useState(null);
  const [outline, setOutline] = useState('');
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [apiKeyInfo, setApiKeyInfo] = useState({
    hasProjectKey: false,
    isUsingDefaultKey: false
  });
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');

  useEffect(() => {
    fetchProjectDetail();
    fetchParticipants();
    fetchApiKeyInfo()
  }, [uuid]);

  const fetchProjectDetail = async () => {
    try {
      const response = await axios.get(`/api/projects/uuid/${uuid}`);
      setProject(response.data);
      setOutline(response.data.outline);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch project detail:', error);
      setLoading(false);
      setSnackbar({ open: true, message: 'Failed to fetch project details' });
    }
  };
  
  const fetchParticipants = async () => {
    try {
      const response = await axios.get(`/api/projects/uuid/${uuid}/participants`);
      console.log('Participants data fetched from API:', response.data.participants);
      setParticipants(response.data.participants);
    } catch (error) {
      console.error('Failed to fetch participants:', error);
      setSnackbar({ open: true, message: 'Failed to fetch participants' });
    }
  };
  
  
  const handleSaveOutline = async () => {
    try {
      await axios.put(`/api/projects/outline/${uuid}`, { outline });
      setSnackbar({ open: true, message: t('Outline saved') });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save outline:', error);
      setSnackbar({ open: true, message: t('Failed to save outline') });
    }
  };

  const fetchApiKeyInfo = async () => {
    try {
      const response = await axios.get(`/api/projects/uuid/${uuid}/api-key-info`);
      setApiKeyInfo({
        hasProjectKey: response.data.has_project_key,
        isUsingDefaultKey: response.data.is_using_default_key
      });
    } catch (error) {
      console.error('Failed to fetch API key info:', error);
    }
  };

  const handleUpdateApiKey = async () => {
    try {
      await axios.put(`/api/projects/uuid/${uuid}/api-key`, {
        api_key: newApiKey
      });
      setSnackbar({ open: true, message: t('API key updated successfully') });
      setShowApiKeyDialog(false);
      fetchApiKeyInfo();
    } catch (error) {
      setSnackbar({ 
        open: true, 
        message: error.response?.data?.detail || t('Failed to update API key') 
      });
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchApiKeyInfo();
    }
  }, [uuid]);

  const handleDownloadData = async () => {
    try {
      setDownloadProgress(0);
      const response = await axios.get(`/api/projects/export/${uuid}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted);
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-${uuid}-all-data.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadProgress(0);
    } catch (error) {
      console.error('Failed to download data:', error);
      setSnackbar({ open: true, message: t('Download failed') });
    }
  };

  const handleDownloadParticipantsCSV = async () => {
    try {
      const response = await axios.get(`/api/projects/uuid/${uuid}/participants/csv`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-${uuid}-participants.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to download participants CSV:', error);
      setSnackbar({ open: true, message: t('Download failed') });
    }
  };

  const handleDeleteParticipant = async (interviewUuid) => {
    if (window.confirm(t('Are you sure you want to delete this participant? This action cannot be undone.'))) {
      try {
        // Make DELETE request to backend
        await axios.delete(`/api/interviews/${interviewUuid}`);
        
        // Show success message
        setSnackbar({ open: true, message: t('Succesfully deleted') });
        
        // Re-fetch participants to update the list
        fetchParticipants();
      } catch (error) {
        console.error('Not possible to delete a single interview:', error);
        setSnackbar({ 
          open: true, 
          message: error.response?.data?.detail || t('Fail to delete participant') 
        });
      }
    }
  };
  
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            {project.name}
          </Typography>

          {/* Interview Outline Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.outline')}</Typography>
            <TextField
              value={outline}
              onChange={(e) => setOutline(e.target.value)}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
              disabled={!isEditing}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isEditing ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveOutline}
                >
                  {t('projectDetail.actions.saveOutline')}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => setIsEditing(true)}
                >
                  {t('common.buttons.edit')}
                </Button>
              )}
            </Box>
          </Box>

          {/* Data Management Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.data')}</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                  onClick={handleDownloadData}
                >
                  {t('projectDetail.actions.downloadData')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={handleDownloadParticipantsCSV}
                >
                  {t('projectDetail.actions.downloadCsv')}
                </Button>
              </Grid>
            </Grid>
            {downloadProgress > 0 && (
              <Box sx={{ mt: 2 }}>
                <LinearProgress variant="determinate" value={downloadProgress} />
              </Box>
            )}
          </Box>

          {/* Participants Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.participants')}</Typography>
            {participants.length > 0 ? (
              <Table sx={{ mt: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('interview.fields.name')}</TableCell>
                    <TableCell>{t('interview.fields.age')}</TableCell>
                    <TableCell>{t('interview.fields.gender')}</TableCell>
                    <TableCell>{t('interview.fields.occupation')}</TableCell>
                    <TableCell>{t('interview.fields.education')}</TableCell>
                    <TableCell>{t('common.buttons.delete')}</TableCell>
                  </TableRow>
                </TableHead>                
                <TableBody>
                  {participants.map((participant) => (
                    <TableRow key={participant.interview_uuid}>
                      <TableCell>{participant.name}</TableCell>
                      <TableCell>{participant.age}</TableCell>
                      <TableCell>{participant.gender}</TableCell>
                      <TableCell>{participant.occupation}</TableCell>
                      <TableCell>{participant.education}</TableCell>
                      <TableCell>
                        <Tooltip title={t('projectDetail.actions.deleteParticipant')}>
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeleteParticipant(participant.interview_uuid)}
                            aria-label={t('projectDetail.actions.deleteParticipant')}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="body1" sx={{ mt: 2 }}>
                {t('projectDetail.messages.noParticipants')}
              </Typography>
            )}
          </Box>
        </Paper>
      )}
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </Container>
  );
};

export default ProjectDetail;
